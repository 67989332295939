import Flickity from 'flickity';

export default function homeHeroCarousel() {

	const heroCarousel = document.querySelector('.hero-carousel');
	const carouselTitles = document.querySelector('.carousel-titles');

	if (heroCarousel === null && carouselTitles === null) {

		return;

	}

	const Flickity = require('flickity-fade');

	const settings = {

		cellSelector: '.hero-carousel__item',
		draggable: false,
		wrapAround: true,
		prevNextButtons: true,
		pageDots: false,
		contain: true,
		setGallerySize: false,
		imagesLoaded: true,
		autoPlay: 4000,
		fade: true

	}

	const flkty = new Flickity(heroCarousel, settings);

	const carouselTitlesUi = () => {

		const titles = carouselTitles.querySelectorAll('.carousel-titles__title');

		const selectActiveTitle = (index) => {

			for (let i = 0; i < titles.length; i++) {

				titles[i].classList.remove('active');

				titles[index].classList.add('active');

			}

		}

		selectActiveTitle(flkty.selectedIndex);

	}

	const carouselBttnsUi = (e) => {

		if (e.target.matches('.carousel-ui__button--previous')) {

			e.preventDefault();

			flkty.previous();

			return;

		}

		if (e.target.matches('.carousel-ui__button--next')) {

			e.preventDefault();

			flkty.next();

			return;

		}

	}

	carouselTitlesUi();

	flkty.on('select', () => carouselTitlesUi());

	document.addEventListener('click', carouselBttnsUi, false);

}

window.addEventListener('DOMContentLoaded', homeHeroCarousel, false);
